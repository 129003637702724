/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import * as jwt from "@/services/jwt";
import JwtDecode from "jwt-decode";

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
};

Vue.use(Vuex);
export default {
  namespaced: true,
  state: {
    userName: "",
    building: [],
    role: "",
    roleId: "",
    status: "",
    access: {},
    avatar: "",
    authorized: /* process.env.VUE_APP_AUTHENTICATED || */ false, // false is default value
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      });
    },
    SET_BUILDING(state, payload) {
      state.building = payload;
    },

    SET_AVATAR(state, payload) {
      state.avatar = payload;
    },
  },
  actions: {
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      return new Promise((resolve, reject) => {
        const { userName, password } = payload;
        commit("SET_STATE", {
          loading: true,
        });

        const login = mapAuthProviders[rootState.settings.authProvider].login;
        login(userName, password)
          .then((success) => {
            if (success) {
              Vue.prototype.$notification.success({
                message: "Connecté",
                description: "Vous vous êtes connecté avec succès",
              });
              const { building, role, status, userName, avatar, edtrustAdmin } =
                success;
              commit("SET_STATE", {
                userName: userName,
                building: building,
                status: status,
                role: role.role,
                roleId: role._id,
                access: role.access,
                avatar: avatar,
                authorized: true,
                edtrustAdmin: edtrustAdmin,
              });
              resolve(success);
            }
            if (!success) {
              reject();
            }
          })
          .finally(() => {
            commit("SET_STATE", {
              loading: false,
            });
          });
      });
    },
    TryAutoLogin({ dispatch, commit, rootState }, payload) {
      const token = localStorage.getItem("accessToken");
      try {
        if (!!token) {
          var decoded = JwtDecode(token);
          if (decoded.exp < Date.now() / 1000) {
            commit("SET_STATE", {
              userName: "",
              building: [],
              role: "",
              roleId: "",
              status: "",
              access: {},
              avatar: "",
              authorized: false,
              loading: false,
            });
            localStorage.removeItem("accessToken");
          } else {
            const currentAccount =
              mapAuthProviders[rootState.settings.authProvider].currentAccount;
            console.log("try auto login..");

            currentAccount().then((success) => {
              if (success) {
                commit("SET_STATE", {
                  userName: decoded.username,
                  status: decoded.role.status,
                  role: decoded.role.role,
                  roleId: decoded.role._id,
                  access: decoded.role.access,
                  authorized: true,
                });
              }
              if (!success) {
                console.log("!success");
                dispatch("LOGOUT");
              }
            });
          }
        } else {
          dispatch("LOGOUT");
        }
      } catch (error) {
        dispatch("LOGOUT");
      }
    },
    LOGOUT({ dispatch, commit, rootState }) {
      console.log("logging out");
      const logout = mapAuthProviders[rootState.settings.authProvider].logout;
      logout().then(() => {
        dispatch("settings/RESET_SETTINGS", {}, { root: true });
        commit("SET_STATE", {
          userName: "",
          building: [],
          role: "",
          roleId: "",
          status: "",
          access: {},
          avatar: "",
          authorized: false,
          loading: false,
        });
        router.push("/auth/login");
      });
    },
    setAvatar({ commit }, payload) {
      commit("SET_AVATAR", payload);
    },
  },
  getters: {
    user: (state) => state,
    building: (state) => state.building,
  },
};
