<template>
  <div :class="$style.container">
    <template v-for="item in favs" class="d-inline">
      <a-tooltip placement="bottom" :key="item.key">
        <template slot="title">{{ $t(item.title) }}</template>
        <span>
          <router-link :to="item.url" :class="$style.item" class="mr-2">
            <i :class="[$style.icon, item.icon]" />
          </router-link>
        </span>
      </a-tooltip>
    </template>
    <a-tooltip placement="bottom">
      <template slot="title">Bookmarks</template>
      <span :class="$style.item">
        <a-dropdown
          :visible="dropdownVisible"
          :trigger="['click']"
          placement="bottomLeft"
        >
          <div :class="$style.dropdown" @click="toggleDropdown">
            <i class="fa fa-star" :class="$style.icon"></i>
          </div>
          <div slot="overlay">
            <div class="card cui__utils__shadow width-350">
              <div class="card-body p-1">
                <div class="p-2">
                  <a-input
                    v-model="searchText"
                    @change="filterPagesList"
                    allowClear
                    :placeholder="$t('topBar.findPages')"
                  />
                </div>
                <div class="height-200" :style="{ 'overflow-y': 'auto' }">
                  <div class="px-2 pb-2">
                    <template v-for="item in filteredPagesList">
                      <router-link
                        :key="item.key"
                        :to="item.url"
                        :class="$style.link"
                      >
                        <div
                          :class="{
                            [$style.setIcon]: true,
                            [$style.setIconActive]: item.isActive,
                          }"
                          @click="(e) => setFav(e, item)"
                        >
                          <i class="fa fa-star" />
                        </div>
                        <span>
                          <i class="mr-2" :class="item.icon" />
                          {{ $t(item.title) }}
                        </span>
                      </router-link>
                    </template>
                  </div>
                </div>
                <div class="p-2">
                  <a-button type="primary" @click="toggleDropdown"
                    >Close</a-button
                  >
                </div>
              </div>
            </div>
          </div>
        </a-dropdown>
      </span>
    </a-tooltip>
  </div>
</template>

<script>
/* eslint-disable */
import store from "store";
import { mapState, mapGetters } from "vuex";
import { getMenuData } from "@/services/menu";
import apiClient from "@/services/axios";

export default {
  data: function () {
    return {
      dropdownVisible: false,
      searchText: "",
      favs: store.get("app.topbar.favs") || [],
      pagesList: [],
      menuData: getMenuData,
      filteredPagesList: [],
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
    ...mapGetters("user", ["user"]),
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },
  mounted() {
    this.loadPagesList();
    this.filterPagesList();
  },
  methods: {
    updateUserFav(favUpdated) {
      const building = store.get("DB");
      store.set("DB", "Root");
      const username = this.$store.state.user.userName;
      apiClient.patch("/users", {
        userName: username,
        updates: { fav: favUpdated },
      });
      store.set("DB", building);
    },
    loadPagesList() {
      const pagesList = () => {
        const menuData = this.menuData;
        const _menuData = JSON.parse(JSON.stringify(menuData));
        const flattenItems = (items, key) =>
          items.reduce((flattenedItems, item) => {
            if (item.category) {
              return flattenedItems;
            }

            if (!this.verifyRole(item) || !this.verifyBuildingAccess(item))
              return flattenedItems;

            if (item.key === "nestedItem1" || item.disabled) {
              // skip unwanted items
              return flattenedItems;
            }
            if (Array.isArray(item[key])) {
              const items = item[key].map((child) => {
                child.icon = item.icon;
                return child;
              });
              return flattenedItems.concat(flattenItems(items, key));
            }
            flattenedItems.push(item);
            return flattenedItems;
          }, []);
        return flattenItems(_menuData, "children");
      };
      this.pagesList = pagesList();
    },
    filterPagesList() {
      const pagesList = this.pagesList;
      const favs = this.favs;
      const _searchText = this.searchText ? this.searchText.toUpperCase() : "";
      const getFilteredPageList = () => {
        const list = [];
        pagesList.forEach((item) => {
          const isActive = favs.some((child) => child.url === item.url);
          if (!item.title.toUpperCase().includes(_searchText) && _searchText) {
            return null;
          }
          item.isActive = isActive;
          list.push(item);
          return null;
        });
        return list;
      };
      this.filteredPagesList = getFilteredPageList();
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    setFav(e, item) {
      e.preventDefault();
      e.stopPropagation();
      const favs = this.favs;
      const isActive = favs.some((child) => child.url === item.url);
      if (isActive) {
        const filtered = favs.filter((child) => child.url !== item.url);
        store.set("app.topbar.favs", filtered);
        this.favs = filtered;
        this.updateUserFav(filtered);
        this.filterPagesList();
        return;
      }
      if (favs.length >= 3) {
        this.$message.info("Only three pages can be added to your bookmarks.");
        return;
      }
      const items = [...favs];
      items.push(item);
      store.set("app.topbar.favs", items);
      this.favs = items;
      this.updateUserFav(items);
      this.filterPagesList();
    },
    verifyBuildingAccess: function (item) {
      if (!item.buildingAccess) return true;
      else {
        const buildingAccess = item.buildingAccess;
        if (this.schoolDetails.access.edManager[buildingAccess]) return true;
        else return false;
      }
    },
    verifyRole: function (item) {
      const existing = this.user.access;
      if (existing.all) return true;
      const required = item.access;
      if (
        required.some(
          (val) =>
            existing[val] === true ||
            existing[item.accessGroup] === true || // for old bulletin access (bulletin was a boolean not an array)
            (existing[item.accessGroup] &&
              existing[item.accessGroup].length > 0 &&
              existing[item.accessGroup].includes(val))
        )
      )
        return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
