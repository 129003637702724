/* eslint-disable */
import axios from "axios";
import store from "store";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api/web",
  "Content-Type": "application/json",
});

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get("accessToken");
  const DB = store.get("DB");
  const url = request.url;
  
  if (
    url.startsWith("/users") ||
    request.url.startsWith("/buildings") ||
    request.url.startsWith("/roles")
  ) {
    request.headers.db = "Root";
  } else {
    if (DB) {
      request.headers.db = DB;
    }
  }
  if (accessToken) {
    request.headers.authorization = `Bearer ${accessToken}`;
    request.headers.AccessToken = accessToken;
  }
  const SchoolarYear = store.get("app.settings.activeSchoolarYear");
  if (SchoolarYear) request.headers.SchoolarYear = SchoolarYear;
  return request;
});

export default apiClient;
