/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import settings from "./settings";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      /* storage: window.sessionStorage, */
    }),
  ],
  modules: {
    user,
    settings,
  },
  state: {},
  mutations: {},
  actions: {},
});
