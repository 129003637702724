<template>
  <!-- <div :style="{ direction: settings.locale == 'ar-AR' ? 'rtl' : 'ltr' }"> -->
  <div>
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";

import { mapState } from "vuex";
import english from "@/locales/en-US";
import arabic from "@/locales/ar-AR";
import french from "@/locales/fr-FR";

arabic.localeAntd.locale = "ar-tn";

const locales = {
  "en-US": english,
  "fr-FR": french,
  "ar-AR": arabic,
};

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: "en-US",
  fallbackLocale: "en-US",
  messages: {
    "en-US": locales["en-US"].messages,
    "fr-FR": locales["fr-FR"].messages,
    "ar-AR": locales["ar-AR"].messages,
  },
});

export default {
  name: "Localization",
  mounted() {
    this.$i18n.locale = this.settings.locale;
  },
  computed: {
    ...mapState(["settings"]),
    locale() {
      return locales[this.settings.locale].localeAntd;
    },
  },
  watch: {
    "settings.locale": function (value) {
      this.$i18n.locale = value;
    },
  },
};
</script>
