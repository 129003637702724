/* eslint-disable */
import "ant-design-vue/lib/style/index.less"; // antd core styles
import "./components/kit/vendors/antd/themes/default.less"; // default theme antd components
import "./components/kit/vendors/antd/themes/dark.less"; // dark theme antd components
import "./global.scss"; // app & third-party component styles
import { log, error } from "./utilities/logger";
import Vue from "vue";
import VuePageTitle from "vue-page-title";
import NProgress from "vue-nprogress";
import VueGtag from "vue-gtag";

import "vue-multiselect/dist/vue-multiselect.min.css";
/* import BootstrapVue from "bootstrap-vue"; */

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./localization";
import "./antd";
import "./registerServiceWorker";
import Schedule from "vue-schedule";
import VueApexCharts from "vue-apexcharts";
import VueEasyLightbox from "vue-easy-lightbox";

import { FormModel } from "ant-design-vue";

import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

const socket = io(process.env.VUE_APP_SOCKET_URL, {
  transports: ["websocket"],
  path: process.env.VUE_APP_SOCKET_PATH,
  upgrade: false,
});

socket.on("connect", () => {
  console.log("socket connected", socket.id); // "G5p5..."
});
socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});

Vue.use(Schedule);
Vue.use(VueEasyLightbox);

Vue.use(VueSocketIOExt, socket, { store });

Vue.use(FormModel);

Vue.use(NProgress);
Vue.use(VuePageTitle, {
  prefix: "Système de gestion: ",
  router,
});
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;
const nprogress = new NProgress({ parent: "body" });

Vue.prototype.log = log;
Vue.prototype.error = error;

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      params: {
        send_page_view: true,
      },
    },
    enabled: process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLED == "true",
  },
  router
);

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
