<template>
  <a-dropdown
    :trigger="['click']"
    placement="bottomLeft"
    @visibleChange="addCount"
  >
    <div :class="$style.dropdown">
      <a-badge>
        <a-avatar
          v-if="!avatar"
          shape="square"
          icon="user"
          size="large"
          :class="$style.avatar"
        />
        <a-avatar
          v-else
          shape="square"
          :src="settings.base_url + '/' + user.avatar"
          size="large"
          :class="$style.avatar"
        />
      </a-badge>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <div>
          <strong
            >{{ $t("topBar.profileMenu.hello") }},
            {{ firstName || "Anonyme" }}</strong
          >
        </div>
        <div>
          <strong class="mr-1">{{ $t("topBar.profileMenu.role") }}:</strong>
          {{ user.role || "—" }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <router-link to="/profile">
          <a-icon type="user" />
          {{ $t("menu.profile") }}
        </router-link>
      </a-menu-item>
      <a-menu-item>
        <a href="javascript: void(0);" @click="logout">
          <a-icon type="logout" />
          {{ $t("menu.logout") }}
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import store from "store";
import apiClient from "@/services/axios";

export default {
  computed: {
    ...mapState(["user", "settings"]),
    loading() {
      return this.$store.state.user.loading;
    },
  },
  data: function () {
    return {
      count: 7,
      firstName: undefined,
      avatar: undefined,
    };
  },
  methods: {
    ...mapActions({
      userLogout: "user/LOGOUT",
    }),
    async logout() {
      this.$socket.client.emit("adminLeave");
      this.$socket.client.removeAllListeners();
      store.set("app.topbar.favs", "");
      this.userLogout();
    },
    addCount() {
      this.count++;
    },
  },
  sockets: {
    desactivation(data) {
      if (data.userName === this.user.userName) {
        this.$notification.open({
          message: this.$t("notifications.desactivation"),
          icon: <i class="fa fa-exclamation-triangle text-danger" />,
        });
        this.userLogout();
      }
    },
    desactivateBuilding(data) {
      console.log("socket data desactivateBuilding :", data);
      if (data === this.settings.activeBuildingId) {
        this.$notification.open({
          message: this.$t("notifications.desactivateBuilding"),
          icon: <i class="fa fa-exclamation-triangle text-danger" />,
        });
        this.userLogout();
      }
    },
    updateuser(data) {
      console.log("sockets updated", data);
      if (data.userName === this.user.userName) {
        this.$notification.open({
          message: this.$t("notifications.updateuser"),
          icon: <i class="fa fa-exclamation text-warning" />,
        });
        this.userLogout();
      }
    },
    updateAccess(data) {
      console.log("sockets updated", data);
      if (data.accessId === this.user.roleId) {
        this.$notification.open({
          message: this.$t("notifications.updateAccess"),
          icon: <i class="fa fa-exclamation text-warning" />,
        });
        this.userLogout();
      }
    },
  },
  async mounted() {
    if (this.user.avatar) this.avatar = this.user.avatar;
    if (this.user) {
      await apiClient.get("/users/" + this.user.userName).then((result) => {
        const data = result.data;
        this.firstName = data.userName;
        this.avatar = data.avatar;
      });
    }
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
