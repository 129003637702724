<template>
  <a-layout-footer
    :class="'footerAuth'"
    :style="mainTheme ? mainStyle : authStyle"
  >
    <a-row
      :style="mainTheme ? '' : 'padding:10px;'"
      align="middle"
      type="flex"
      :gutter="9"
      justify="center"
    >
      <a-col>
        <a href="https://edtrust.tn/">
          <img
            style="object-fit: cover; width: 50px; height: 50px"
            src="favicon.png"
          />
        </a>
      </a-col>
      <a-col>
        <a-row type="flex">
          <a-col :span="24">
            <strong style="color: #1f1f57">
              <a href="https://edtrust.tn/">EdTrust</a>
            </strong>
          </a-col>
          <a-col :span="24" class="text-muted">
            <span>Copyright &copy; 2021 Tous Droits Réservés. EdTrust</span>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-layout-footer>
</template>

<script>
import store from "store";
export default {
  props: {
    mainTheme: Boolean,
  },
  methods: {
    styling(cssRgbColor) {
      this.authStyle.backgroundColor = cssRgbColor;
      this.mainStyle.backgroundColor = cssRgbColor;
    },
  },
  mounted() {
    store.get("app.settings.theme") === "dark"
      ? this.styling("rgba(20, 19, 34,0.8)")
      : this.styling("rgba(255, 255, 255, 0.8)");
  },
  data() {
    return {
      authStyle: {
        position: "fixed",
        bottom: "0px",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        width: "100%",
      },
      mainStyle: { backgroundColor: "rgba(255, 255, 255, 0.8)" },
    };
  },
  watch: {
    "$store.state.settings.theme": function (newVal) {
      newVal === "dark"
        ? this.styling("rgba(20, 19, 34,0.8)")
        : this.styling("rgba(255, 255, 255, 0.8)");
    },
  },
};
</script>

<style></style>
