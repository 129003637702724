const isProduction = process.env.NODE_ENV === 'production'

module.exports = {
  log: (message, ...args) => {
    if (!isProduction) console.log(message, args)
  },
  error: (err) => {
    if (!isProduction) console.error(err)
  },
}
