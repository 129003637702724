<template>
  <a-dropdown :trigger="['click']" placement="bottomRight">
    <div :class="$style.dropdown">
      <span class="text-uppercase">
        <img
          style="border-radius: 25%"
          width="20"
          :src="`resources/images/flags/${
            language == 'en'
              ? 'united-states'
              : language == 'fr'
              ? 'france'
              : 'tunisia'
          }.svg`"
          alt="Arabic"
        />
        {{ $t("language." + language) }}</span
      >
    </div>
    <a-menu slot="overlay" @click="selectLocale">
      <a-menu-item key="en-US">
        <img
          style="border-radius: 25%"
          width="20"
          src="resources/images/flags/united-states.svg"
          alt="Arabic"
        />
        {{ $t("language.en") }}
      </a-menu-item>
      <a-menu-item key="fr-FR">
        <img
          style="border-radius: 25%"
          width="20"
          src="resources/images/flags/france.svg"
          alt="Arabic"
        />
        {{ $t("language.fr") }}
      </a-menu-item>
      <a-menu-item key="ar-AR">
        <img
          style="border-radius: 30%"
          width="20"
          src="resources/images/flags/tunisia.svg"
          alt="Arabic"
        />
        {{ $t("language.ar") }}
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["settings"]),
    language() {
      return this.settings.locale.substr(0, 2);
    },
  },
  methods: {
    selectLocale(item) {
      const setting = "locale";
      this.$store.commit("settings/CHANGE_SETTING", {
        setting,
        value: item.key,
      });
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
