/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import AuthLayout from "@/layouts/Auth";
import MainLayout from "@/layouts/Main";
import store from "@/store";
import { getMenuData } from "@/services/menu";
import _ from "lodash";
Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "/elevesliste",
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        {
          path: "/calender",
          meta: {
            title: "Calender",
            roles: ["calendar"],
            accessGroup: "calendar",
          },
          component: () => import("./views/calender/index"),
        },
        {
          path: "/chat",
          meta: {
            title: "chat",
            roles: ["chat"],
            accessGroup: "chat",
          },
          component: () => import("./views/chat/index"),
        },
        {
          path: "/ptchat",
          meta: {
            title: "Chat enseignant parent",
            roles: ["chat"],
            accessGroup: "chat",
          },
          component: () => import("./views/ptchat/index"),
        },
        {
          path: "/teacherAvailability",
          meta: {
            title: "Teacher's availability",
            roles: ["all", "teacher_dispo"],
            accessGroup: "emplois",
          },
          component: () => import("./views/scolarite/teacherAvailability"),
        },
        {
          path: "/smart-schedule",
          meta: {
            title: "Smart schedule table",
            roles: ["all", "smart_schedules"],
            accessGroup: "emplois",
          },
          component: () => import("./views/scolarite/smartSchedule"),
        },
        {
          path: "/smart-schedule/:schedParamId",
          meta: {
            title: "Smart schedule",
            roles: ["all", "smart_schedules"],
            accessGroup: "emplois",
          },
          component: () =>
            import("./views/scolarite/smartSchedule/viewSchedule"),
        },
        // Dashboards
        {
          path: "/autorisation",
          meta: {
            title: "autorisation",
            roles: ["autorisation"],
            accessGroup: "adminstration",
          },
          component: () => import("./views/administration/autorisation"),
        },
        {
          path: "/addAutorisation",
          meta: {
            title: "addAutorisation",
            roles: ["autorisation"],
            accessGroup: "adminstration",
          },
          component: () => import("./views/administration/addAutorisation"),
        },
        {
          path: "/utilisateur",
          meta: {
            title: "utilisateur",
            roles: ["utilisateur"],
            accessGroup: "adminstration",
          },
          component: () => import("./views/administration/utilisateur"),
        },
        {
          name: "homePage",
          path: "/accueil",
          meta: {
            title: "Accueil",
            roles: ["home"],
            accessGroup: "home",
          },
          component: () => import("./views/accueil"),
        },
        {
          path: "/etablissement",
          meta: {
            title: "Paramétrage - Etablissement",
            roles: ["all", "etablissement"],
            accessGroup: "parametrage",
          },
          component: () => import("./views/parametrage/etablissement"),
        },
        {
          path: "/comptabilite",
          meta: {
            title: "Paramétrage - Comptabilité",
            roles: ["all", "comptabilite"],
            accessGroup: "parametrage",
          },
          component: () => import("./views/parametrage/comptabilite"),
        },
        {
          path: "/scolarite",
          meta: {
            title: "Paramétrage - Scolarité",
            roles: ["all", "scolarite"],
            accessGroup: "parametrage",
          },
          component: () => import("./views/parametrage/scolarite"),
        },
        {
          path: "/smartscheduleParams",
          meta: {
            title: "Paramétrage - smart schedule",
            roles: ["all", "smart_schedule"],
            accessGroup: "parametrage",
          },
          component: () => import("./views/parametrage/smartSchedule"),
        },
        {
          path: "/bulletin",
          meta: {
            title: "Paramétrage - Bulletin",
            roles: ["all", "bulletin"],
            accessGroup: "parametrage",
          },
          component: () => import("./views/parametrage/bulletin"),
        },
        {
          path: "/sms-settings",
          meta: {
            title: "Paramètres de SMS automatiques",
            roles: ["all", "sms"],
            accessGroup: "parametrage",
          },
          component: () => import("./views/parametrage/sms"),
        },
        {
          path: "/etatPaiementActivite",
          meta: {
            title: "etatPaiementActivite",
            roles: ["etat_paiement"],
            accessGroup: "activite",
          },
          component: () => import("./views/activites/etatPaiement"),
        },
        {
          path: "/cantine",
          meta: {
            title: "cantine",
            roles: ["cantine"],
            accessGroup: "activite",
          },
          component: () => import("./views/cantine"),
        },
        {
          path: "/PaiementActivite/:activityId",
          meta: {
            title: "etatPaiementActivite",
            roles: ["etat_paiement"],
            accessGroup: "activite",
          },
          component: () =>
            import("./views/activites/etatPaiement/activityPayment.vue"),
          props: true,
        },
        {
          path: "/inscriptionActivites",
          meta: {
            title: "inscriptionActivites",
            roles: ["inscription"],
            accessGroup: "activite",
          },
          component: () => import("./views/activites/inscriptionActivites"),
        },
        {
          path: "/inscriptionScolarite",
          meta: {
            title: "inscriptionScolarité",
            roles: ["inscription"],
            accessGroup: "scolarite",
          },
          component: () => import("./views/scolarite/inscriptionScolarite"),
        },
        {
          path: "/listePunitions",
          meta: {
            title: "listePunitions",
            roles: ["punition"],
            accessGroup: "scolarite",
          },
          component: () => import("./views/scolarite/listePunitions"),
        },
        {
          path: "/schedule",
          meta: {
            title: "Student schedule",
            roles: ["eleves"],
            accessGroup: "emplois",
          },
          component: () => import("./views/scolarite/schedule"),
        },
        {
          path: "/teacherschedule",
          meta: {
            title: "Teacher schedule",
            roles: ["enseignant"],
            accessGroup: "emplois",
          },
          component: () => import("./views/scolarite/teacherSchedule"),
        },

        {
          path: "/bulletin/notemodule",
          meta: {
            title: "Bulletin pédagogique - Notes par matière",
            roles: ["bulletin", "all", "pedagogic"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletin/marksModule"),
        },
        {
          path: "/bulletin/notemodulecomplet",
          meta: {
            title: "Bulletin pédagogique - Note par module",
            roles: ["bulletin", "all", "pedagogic"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletin/marksModuleComplet"),
        },
        {
          path: "/bulletin/noteeleve",
          meta: {
            title: "Bulletin pédagogique - Notes par élève",
            roles: ["bulletin", "all", "pedagogic"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletin/studentMarks"),
        },
        {
          path: "/bulletin/appreciationAdmin",
          meta: {
            title: "Appréciation administrative",
            roles: ["bulletin", "all", "pedagogic"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletin/appreciationAdmin"),
        },
        {
          path: "/bulletin/certification",
          meta: {
            title: "Certification Pédagogique",
            roles: ["bulletin", "all", "pedagogic"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletin/certification"),
        },
        {
          path: "/specbulletin/notemodule",
          meta: {
            title: "Bulletin pilote - Notes par matière",
            roles: ["bulletin", "all", "pilote"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletinSpecifique/marksModule"),
        },
        {
          path: "/specbulletin/notemodulecomplet",
          meta: {
            title: "Bulletin pilote - Notes par module",
            roles: ["bulletin", "all", "pilote"],
            accessGroup: "bulletin",
          },
          component: () =>
            import("./views/bulletinSpecifique/marksModuleComplet"),
        },
        {
          path: "/specbulletin/noteeleve",
          meta: {
            title: "Bulletin pilote - Notes par élève",
            roles: ["bulletin", "all", "pilote"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletinSpecifique/studentMarks"),
        },
        {
          path: "/veryspecbulletin/notematiere",
          meta: {
            title: "Bulletin specifique - Notes par matière",
            roles: ["bulletin", "all", "specific"],
            accessGroup: "bulletin",
          },
          component: () =>
            import("./views/bulletinVerySpecific/marksParMatiere"),
        },
        {
          path: "/veryspecbulletin/notemodule",
          meta: {
            title: "Bulletin specifique - Notes par module",
            roles: ["bulletin", "all", "specific"],
            accessGroup: "bulletin",
          },
          component: () =>
            import("./views/bulletinVerySpecific/marksModuleComplet"),
        },
        {
          path: "/veryspecbulletin/noteeleve",
          meta: {
            title: "Bulletin specifique - Notes par élève",
            roles: ["bulletin", "all", "specific"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletinVerySpecific/studentMarks"),
        },
        {
          path: "/bulletinStatistique",
          meta: {
            title: "Bulletin statistique",
            roles: ["bulletin", "all", "statistique"],
            accessGroup: "bulletin",
          },
          component: () => import("./views/bulletinStatistique"),
        },
        {
          path: "/etatDesImpayes",
          meta: {
            title: "etatDesImpayés",
            roles: ["etat_paiement"],
            accessGroup: "scolarite",
          },
          component: () => import("./views/scolarite/etatDesImpayes"),
        },
        {
          path: "/etatImpayesActivites",
          meta: {
            title: "etatImpayesActivites",
            roles: ["etat_impayesA"],
            accessGroup: "declaration",
          },
          component: () => import("./views/declarations/etatImpayesActivites"),
        },
        {
          path: "/etatImpayesScolarite",
          meta: {
            title: "etatImpayesScolarite",
            roles: ["etat_impayesS"],
            accessGroup: "declaration",
          },
          component: () => import("./views/declarations/etatImpayesScolarite"),
        },
        {
          path: "/declarationTVA",
          meta: {
            title: "declarationTVA",
            roles: ["declarationTVA"],
            accessGroup: "declaration",
          },
          component: () => import("./views/declarations/declarationTVA"),
        },
        {
          path: "/chiffreAffaire",
          meta: {
            title: "chiffreAffaire",
            roles: ["chiffre_affaire"],
            accessGroup: "declaration",
          },
          component: () => import("./views/declarations/chiffreAffaire"),
        },
        {
          path: "/chiffreAffaireScolarite",
          meta: {
            title: "chiffreAffaireScolarite",
            roles: ["chiffre_affaireS"],
            accessGroup: "declaration",
          },
          component: () =>
            import("./views/declarations/chiffreAffaireScolarite"),
        },
        {
          path: "/listePersonnel",
          meta: {
            title: "listePersonnel",
            roles: ["personnel"],
            accessGroup: "personnel",
          },
          component: () => import("./views/personnel/listePersonnel"),
        },
        {
          path: "/listeEnseignant",
          meta: {
            title: "listeEnseignants",
            roles: ["enseignant"],
            accessGroup: "personnel",
          },
          component: () => import("./views/personnel/listeEnseignant"),
        },
        {
          path: "/etatDePaiement",
          meta: {
            title: "etatDePaiement",
            roles: ["etat_paiement"],
            accessGroup: "personnel",
          },
          component: () => import("./views/personnel/etatDePaiement"),
        },
        {
          path: "/virementBancaire",
          meta: {
            title: "virementBancaire",
            roles: ["viremenet_bancaire"],
            accessGroup: "personnel",
          },
          component: () => import("./views/personnel/virementBancaire"),
        },
        // Tables
        {
          path: "/elevesliste",
          meta: {
            title: "Liste des élèves",
            roles: ["liste_eleves"],
            accessGroup: "liste_eleves",
          },
          component: () => import("./views/eleves"),
        },
        {
          path: "/archive_elevesliste",
          meta: {
            title: "Liste des élèves archivée",
            roles: ["liste_eleves"],
            accessGroup: "liste_eleves",
          },
          component: () => import("./views/eleves/archive"),
        },
        {
          path: "/preinscription",
          meta: {
            title: "Pré-inscription",
            roles: ["liste_eleves"],
            accessGroup: "liste_eleves",
          },
          component: () => import("./views/preinscription"),
        },
        //xlsx uploader
        {
          path: "/uploadeleves",
          meta: {
            title: "Upload - Liste des élèves",
            roles: ["liste_eleves"],
            accessGroup: "liste_eleves",
          },
          component: () => import("./views/eleves/xlsxUploader"),
        },
        {
          path: "/sms/envoyer",
          meta: {
            title: "Envoyer SMS",
            roles: ["all", "send"],
            accessGroup: "sms",
          },
          component: () => import("./views/sms/send"),
        },
        {
          path: "/sms/envoyerClasse",
          meta: {
            title: "Envoyer par classe",
            roles: ["all", "send"],
            accessGroup: "sms",
          },
          component: () => import("./views/sms/sendClass"),
        },
        {
          path: "/sms/envoyerLogin",
          meta: {
            title: "Envoyer Logins",
            roles: ["all", "send"],
            accessGroup: "sms",
          },
          component: () => import("./views/sms/sendParentLogin"),
        },
        {
          path: "/sms/historique",
          meta: {
            title: "Historique SMS",
            roles: ["all", "history"],
            accessGroup: "sms",
          },
          component: () => import("./views/sms/history"),
        },
        {
          path: "/eleve/:studentId",
          meta: {
            title: "Profile d'élève",
            roles: ["liste_eleves"],
            accessGroup: "liste_eleves",
          },
          component: () => import("./views/eleves/profile"),
          props: true,
        },
        {
          path: "/inscriptionScolarite/:classId",
          meta: {
            title: "inscriptionScolarite",
            roles: ["inscription"],
            accessGroup: "scolarite",
          },
          component: () =>
            import("./views/scolarite/inscriptionScolarite/classInscri.vue"),
          props: true,
        },

        {
          path: "/etatDesRecettes",
          meta: {
            title: "etatDesRecettes",
            roles: ["etat_recettes"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/etatDesRecettes"),
        },
        {
          path: "/caisseavance",
          meta: {
            title: "Caisse avance",
            roles: ["avance"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/avance"),
        },
        {
          path: "/cheques",
          meta: {
            title: "Les cheques",
            roles: ["etat_recettes"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/cheques"),
        },
        {
          path: "/addAvance",
          meta: {
            title: "Ajouter avance",
            roles: ["etat_recettes"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/avance/addAvance"),
        },
        {
          path: "/journalCaisse",
          meta: {
            title: "journalCaisse",
            roles: ["journale_caisse"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/journalCaisse"),
        },
        {
          path: "/depenses",
          meta: {
            title: "depenses",
            roles: ["depenses"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/depenses"),
        },
        {
          path: "/fournisseur/:providerId",
          meta: {
            title: "Provider Profile",
            roles: ["depenses"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/fournisseurProfile"),
        },
        {
          path: "/etatDesImpayesC",
          meta: {
            title: "etatDesImpayes",
            roles: ["etat_impayes"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/etatDesImpayes"),
        },
        {
          path: "/ajouteRecette",
          meta: {
            title: "ajouteRecette",
            roles: ["etat_recettes"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/ajouteRecette"),
        },
        {
          path: "/listeReglement",
          meta: {
            title: "listeReglement",
            roles: ["liste_reglement"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/listeReglement"),
        },
        {
          path: "/reglementCaisse",
          meta: {
            title: "reglementCaisse",
            roles: ["liste_reglement"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/reglementCaisse"),
        },
        {
          path: "/listeReglementAnnule",
          meta: {
            title: "listeReglementAnnulé",
            roles: ["reglement_annule"],
            accessGroup: "comptabilite",
          },
          component: () => import("./views/comptabilite/listeReglementAnnulé"),
        },
        {
          path: "/actuality",
          meta: {
            title: "Actualité",
            roles: ["actualite"],
            accessGroup: "actualite",
          },
          component: () => import("./views/actuality"),
        },
        {
          path: "/actuality/:id",
          meta: {
            title: "Actualité détails",
            roles: ["actualite"],
            accessGroup: "actualite",
          },
          component: () => import("./views/actuality/singleActuality"),
        },
        {
          path: "/attendance",
          meta: {
            title: "Assiduité",
            roles: ["assiduite"],
            accessGroup: "assiduite",
          },
          component: () => import("./views/attendance"),
        },
        {
          path: "/profile",
          meta: {
            title: "Mon Profile",
            roles: true,
          },
          component: () =>
            import(
              "./components/cleanui/layout/Topbar/UserMenu/components/profile"
            ),
        },
        // Charts
      ],
    },

    // System Pages
    {
      path: "/auth",
      component: AuthLayout,
      redirect: "auth/login",
      children: [
        {
          path: "/auth/404",
          meta: {
            title: "Error 404",
          },
          component: () => import("./views/auth/404"),
        },
        {
          path: "/auth/500",
          meta: {
            title: "Error 500",
          },
          component: () => import("./views/auth/500"),
        },
        {
          path: "/auth/login",
          meta: {
            title: "Sign In",
          },
          component: () => import("./views/auth/login"),
          beforeEnter: (to, from, next) => {
            if (store.state.user.authorized) next({ name: "homePage" });
            else next();
          },
        },
        {
          path: "/auth/register",
          meta: {
            title: "Sign Up",
          },
          component: () => import("./views/auth/register"),
        },
        {
          path: "/auth/forgot-password",
          meta: {
            title: "Forgot Password",
          },
          component: () => import("./views/auth/forgot-password"),
        },
        {
          path: "/auth/lockscreen",
          meta: {
            title: "Lockscreen",
          },
          component: () => import("./views/auth/lockscreen"),
        },
      ],
    },

    // Redirect to 404
    {
      path: "*",
      redirect: "auth/404",
      hidden: true,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("accessToken")) store.dispatch("user/TryAutoLogin");

  if (to.matched.some((record) => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath },
      });
    } else {
      if (store.state.user.access.all) next();
      //route protected
      else if (to.meta.roles === true) next();
      else if (
        to.meta.roles.some(
          (val) =>
            store.state.user.access[to.meta.accessGroup] === true ||
            (store.state.user.access[to.meta.accessGroup] &&
              store.state.user.access[to.meta.accessGroup].includes(val))
        )
      ) {
        next();
      } else {
        next({
          path: "/auth/500",
          query: { redirect: to.fullPath },
        });
      }
    }
    /*  else {
        let menuData = getMenuData.filter((item) => {

          const existing = store.state.user.access;
          if (existing.all) return item;
          const required = item.access;
          if (
            required.some(
              (val) =>
                existing[val] === true ||
                (existing[item.accessGroup] && existing[item.accessGroup].length > 0)
            )
          )
            return item;
        });

        const x = menuData.some((element) => {
          //test path matches child or parent
          let childrenExists = element.children ? element.children.find((e) => {
            return e.url === to.path
          }) : false
          let existing = store.state.user.access[element.accessGroup];
          console.log("childrenExists", childrenExists)

          return (
            store.state.user.access[element.accessGroup] && (element.url === to.path || childrenExists)

          );
        });
        console.log("x:", x, "to path", to.path)
        if (store.state.user.access.all || to.path.includes("/eleve/") || to.path.includes("/PaiementActivite/") || to.path.includes("/inscriptionScolarite/")) next();
        else if (!x) {
          console.info("not X")
          next({ path: menuData[0].url });
        }
        else next();
      }*/
  } else {
    next();
  }

  /* this.menuData = this.menuData.filter((item) => {
      const existing = this.user.access;
      if (existing.all) return item;
      const required = item.access;
      if (existing[required]) return item;
    });

    this.$router.push(this.menuData[0].url) */
});

export default router;
