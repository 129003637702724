<template>
  <div></div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "store";
import find from "lodash/find";
import { getMenuData } from "@/services/menu";
/* import SubMenu from './partials/submenu'
import Item from './partials/item' */

export default {
  name: "menu-top",
  /*  components: { SubMenu, Item }, */
  computed: {
    ...mapState(["settings"]),
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    this.selectedKeys = store.get("app.menu.selectedKeys") || [];
    this.setSelectedKeys();
  },
  data() {
    return {
      menuData: getMenuData,
      selectedKeys: [],
      openKeys: [],
    };
  },
  watch: {
    "settings.isMenuCollapsed"() {
      this.openKeys = [];
    },
    $route() {
      this.setSelectedKeys();
    },
  },
  methods: {
    handleClick(e) {
      if (e.key === "settings") {
        this.$store.commit("settings/CHANGE_SETTING", {
          setting: "isSettingsOpen",
          value: true,
        });
        return;
      }
      store.set("app.menu.selectedKeys", [e.key]);
      this.selectedKeys = [e.key];
    },
    setSelectedKeys() {
      const pathname = this.$route.path;
      const menuData = this.menuData.concat();
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item);
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key));
          }
          return flattenedItems;
        }, []);
      const selectedItem = find(flattenItems(menuData, "children"), [
        "url",
        pathname,
      ]);
      this.selectedKeys = selectedItem ? [selectedItem.key] : [];
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
