<template>
  <div>
    <cui-sidebar />
    <a-select
      show-search
      placeholder="Etablissement"
      :autoFocus="true"
      option-filter-prop="children"
      style="width: 400px"
      class=""
      :filter-option="filterOption"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
      :default-value="settings.activeBuilding"
      v-model="settings.activeBuilding"
    >
      <a-select-option
        :value="building.db"
        v-for="building in settings.image"
        :key="building.db"
      >
        {{ building.name }}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>
/* eslint-disable */
import store from "store";
import { mapState } from "vuex";
import CuiSidebar from "@/components/cleanui/layout/Sidebar";
import router from "@/router";
import apiClient from "@/services/axios";
export default {
  name: "AuthLayout",
  components: { CuiSidebar },
  computed: mapState(["settings", "user"]),
  methods: {
    getCurrentScholarYearId(schoolYears) {
      const currentDate = new Date();

      for (const schoolYear of schoolYears) {
        const startDate = new Date(schoolYear.start);
        const endDate = new Date(schoolYear.end);

        if (currentDate >= startDate && currentDate <= endDate) {
          return schoolYear._id;
        }
      }

      // If no matching school year is found, you can return null or handle it as needed.
      return schoolYears[0]._id;
    },
    async handleChange(value) {
      let buidlingId = this.user.building.find((e) => {
        return e.dbName === value;
      });
      await store.set("DB", value);
      await this.$store.commit("settings/CHANGE_SETTING", {
        setting: "activeBuilding",
        value: value,
      });
      await this.$store.commit("settings/CHANGE_SETTING", {
        setting: "activeBuildingId",
        value: buidlingId._id,
      });
      if (buidlingId.base_url) {
        await this.$store.commit("settings/CHANGE_SETTING", {
          setting: "base_url",
          value: buidlingId.base_url,
        });
        apiClient.defaults.baseURL = buidlingId.base_url + "/api/web";
      } else {
        await this.$store.commit("settings/CHANGE_SETTING", {
          setting: "base_url",
          value: process.env.VUE_APP_API_URL,
        });
        apiClient.defaults.baseURL = process.env.VUE_APP_API_URL + "/api/web";
      }

      await apiClient
        .post("/schoolaryears/filter", {
          query: { $query: {}, $orderby: { end: -1 } },
        })
        .then(async (res) => {
          const data = res.data.map((sc) => {
            const start = new Date(sc["start"]);
            const end = new Date(sc["end"]);
            const startM =
              start.getMonth() < 9
                ? "0" + (start.getMonth() + 1)
                : "" + (start.getMonth() + 1);
            const endM =
              end.getMonth() < 9
                ? "0" + (end.getMonth() + 1)
                : "" + (end.getMonth() + 1);
            return {
              ...sc,
              name:
                startM +
                "-" +
                start.getFullYear() +
                " / " +
                endM +
                "-" +
                end.getFullYear(),
            };
          });
          let activeSchoolarYearId = this.getCurrentScholarYearId(data);

          await this.$store.commit("settings/CHANGE_SETTING", {
            setting: "activeSchoolarYear",
            value: activeSchoolarYearId,
          });
          await this.$store.commit("settings/CHANGE_SETTING", {
            setting: "schoolarYears",
            value: data,
          });
          store.set("schoolarYears", data);
          store.set("SchoolarYear", activeSchoolarYearId);
          store.set("activeSchoolarYear", activeSchoolarYearId);
          router.push("/");
        });
      window.location.reload();
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
