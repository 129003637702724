<template>
  <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
    <span slot="title">
      <span :class="styles.title">{{ $t(menuInfo.title) }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{
        menuInfo.count
      }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
    </span>
    <template v-for="item in menuInfo.children">
      <item
        v-if="verifyRole(item) && verifyBuildingAccess(item)"
        :menu-info="item"
        :styles="styles"
        :key="item.key"
      />
      <sub-menu
        v-if="item.children"
        :menu-info="item"
        :styles="styles"
        :key="item.key"
      />
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from "ant-design-vue";
import Item from "./item";
import { mapGetters, mapState } from "vuex";

export default {
  name: "SubMenu",
  components: { Item },
  isSubMenu: true,

  props: {
    ...Menu.SubMenu.props,
    menuInfo: Object,
    styles: Object,
  },
  computed: {
    ...mapState(["settings", "user"]),
    ...mapGetters("user", ["user"]),
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },
  created() {},
  data() {
    return {};
  },
  methods: {
    verifyRole: function (item) {
      const existing = this.user.access[item.accessGroup];
      // console.log("existing : ", existing);
      if (existing === true || (existing && existing.includes("all")))
        return true;
      const required = item.access;
      // console.info(item.key, required);
      if (
        required.some(
          (val) => existing === true || (existing && existing.includes(val))
        )
      )
        return true;
      else return false;
    },
    verifyBuildingAccess: function (item) {
      if (!item.buildingAccess) return true;
      else {
        const buildingAccess = item.buildingAccess;
        if (this.schoolDetails.access.edManager[buildingAccess]) return true;
        else return false;
      }
    },
  },
};
</script>
